* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

.slick-center {
  transform: scale(1.2);
  transition: 0.7s ease-in-out;
}
@media screen and (max-width:940px) {
  .slick-center {
    transform: none;
  }
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.pb-1{
  padding-bottom: 10px;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h3 {
  font-size: 2rem;
}
