.card{
    margin-top: 3rem ;
    margin-bottom: 3rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    background-color: white;
    border-radius: 20px;
    min-height: 400px;
    display: flex;
    align-items: center;
}
.container-card{
    padding: 1rem;
    height: fit-content;
    width: 100%;
}

.card-img{
    width:50%;
    margin: auto;
    margin-top: 1rem;
    max-width: 150px; 
}

.card-title{
    width:fit-content;
    margin: auto;
    margin-top: 0.5rem;
    font-size: medium;
}

.card-description{
    margin: 1.2rem;
    font-weight: 200;
    text-align: center;
    font-size: small;
}

.about-button{
    margin: auto;
    background-color: lightgray;
    display: flex;
    border: none;
    border-radius: 5px;
    margin-bottom: 1rem;
    color: black;
    padding: 0.6rem;
    
}

.about-button:hover{
    /* cursor:pointer;
    scale: 1.05;
    transition: 0.25s ease-in-out; */
    background-color: #b3b3b3;
    cursor:pointer;
}

.about-button p{
    margin: auto;
    margin-right: 0.25rem;
}

.card-icon{

}

.hide{
    transform: scale(0);
}


